// Packages
import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import PageHeader from "~components/sections/PageHeader";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Ul from "~components/typography/Ul";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	profileImage: IGatsbyImageData;
	infografik: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const profileImage = getImage(data.profileImage);
	const infografik = getImage(data.infografik);

	return (
		<Layout
			location={location}
			title="Profil"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader title="AVGS" />
			<Container>
				<Grid cols={2} className="mb-10">
					<div>
						<H2 className="mb-4">Personalbereitstellung für AVGS – Aktivierungs- und Vermittlungsgutschein</H2>
						<P className="mb-10">
							Unser Unternehmen ist spezialisiert auf die Bereitstellung qualifizierten Personals für
							Bildungseinrichtungen und deren Projekte, die im Einklang mit der Akkreditierungs- und
							Zulassungsverordnung Arbeitsförderung (AZAV) zertifiziert sind. Wir verstehen die Bedeutung einer
							zielgerichteten und effizienten Bildung, die nicht nur Wissen vermittelt, sondern auch die Brücke zum
							Arbeitsmarkt stärkt.
						</P>
						<H2 className="mb-4">Unsere Mission</H2>
						<P>
							Wir bieten Dienstleistungen an, die speziell darauf ausgerichtet sind, die Bildungseinrichtungen und ihre
							Projekte in ihrer Arbeit zu unterstützen, die durch den Aktivierungs- und Vermittlungsgutschein (AVGS)
							gefördert werden. Unser Ziel ist es, durch die Bereitstellung von hochqualifiziertem Personal, das auf die
							spezifischen Bedürfnisse dieser Projekte zugeschnitten ist, einen nahtlosen und effektiven Bildungsprozess
							zu gewährleisten.
						</P>
					</div>
					<div>
						<H2 className="mb-4">Unsere Dienstleistungen umfassen</H2>
						<Ul
							items={[
								"Bereitstellung von Fachpersonal, das in der Lage ist, spezialisierte Bildungsprogramme zu leiten, die den Richtlinien der AZAV entsprechen.",
								"Unterstützung bei der Entwicklung und Durchführung von Bildungsprojekten, die für eine Förderung durch AVGS in Frage kommen.",
								"Beratungsdienstleistungen für Bildungseinrichtungen zur Optimierung ihrer Programme im Hinblick auf die Anforderungen der Arbeitsförderung und zur Steigerung ihrer Erfolgsquoten bei der Arbeitsvermittlung.",
							]}
							className="mb-10"
						/>
						<H2 className="mb-4">Unser Mehrwert:</H2>
						<Ul
							items={[
								<>
									<b>Umsatzsteuerbefreiung</b>: Unsere Dienstleistungen sind gemäß den gesetzlichen Bestimmungen von der
									Umsatzsteuer befreit, was eine kosteneffiziente Lösung für Bildungseinrichtungen darstellt.
								</>,
								<>
									<b>AZAV-Zertifizierung</b>: Wir arbeiten ausschließlich mit Personal, das die Qualitätsstandards der
									AZAV erfüllt, um die höchstmögliche Bildungsqualität sicherzustellen.
								</>,
								<>
									<b>Maßgeschneiderte Lösungen</b>:Unser Ansatz ist es, individuell auf die Bedürfnisse jeder
									Bildungseinrichtung und jedes Projekts einzugehen, um optimale Ergebnisse zu erzielen.
								</>,
							]}
						/>
					</div>
				</Grid>
				<div>
					<P>
						<b>
							Engagieren Sie sich mit uns für die Förderung der Bildung und die Verbesserung der Chancen auf dem
							Arbeitsmarkt.
						</b>{" "}
						Unser Unternehmen steht bereit, um Bildungseinrichtungen in ihrem Streben nach Exzellenz und Erfolg zu
						unterstützen. Kontaktieren Sie uns für weitere Informationen oder um eine Zusammenarbeit zu besprechen.
					</P>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
